import 'components/need-help/NeedHelp.scss'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { LinkButtonIconName, PHeading, PLinkPure, PText } from '@porsche-design-system/components-react'
import { Col, Row } from 'react-bootstrap'
import _ from 'lodash'

type NeedHelpElementProps = {
  children: React.ReactNode
  href?: string
  icon: LinkButtonIconName
  isTargetBlank?: boolean
}
const NeedHelpElement = ({ children, href, icon, isTargetBlank }: NeedHelpElementProps): JSX.Element => {
  return (
    <div className='element'>
      <PLinkPure target={isTargetBlank ? '_blank' : ''} href={href} icon={icon} size='small'>
        {children}
      </PLinkPure>
    </div>
  )
}

export type NeedHelpProperties = {
  qa?: string
  contactDetails?: {
    email?: string
    hotline?: string
    localHotline?: string
  }
  mno?: string
}

const NeedHelp = ({ qa, contactDetails, mno }: NeedHelpProperties): JSX.Element => {
  return (
    <div className='need-help-container' data-testid='need-help'>
      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <PHeading size='large'>
            <FormattedMessage id='app.needHelp.heading' />
          </PHeading>

          <PText className='need-help-text'>
            <FormattedMessage id='app.needHelp.text' values={{ provider: _.capitalize(mno) }} />
          </PText>

          <div className='elements-container'>
            {qa && (
              <NeedHelpElement icon='chat' href={qa} isTargetBlank>
                <FormattedMessage id='app.needHelp.questionsAnswers' />
              </NeedHelpElement>
            )}
            {contactDetails?.email && (
              <NeedHelpElement icon='email' href={`mailto:${contactDetails?.email}`}>
                <FormattedMessage id='app.needHelp.email' values={{ provider: _.capitalize(mno) }} />
              </NeedHelpElement>
            )}

            {contactDetails?.hotline && (
              <NeedHelpElement icon='phone' href={`tel:${contactDetails?.hotline}`}>
                <FormattedMessage id='app.needHelp.hotline' values={{ provider: _.capitalize(mno) }} />
              </NeedHelpElement>
            )}

            {contactDetails?.localHotline && (
              <NeedHelpElement icon='phone' href={`tel:${contactDetails?.localHotline}`}>
                <FormattedMessage id='app.needHelp.localHotline' values={{ provider: _.capitalize(mno) }} />
              </NeedHelpElement>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default NeedHelp
