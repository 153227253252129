import React, { lazy, Suspense, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PSpinner } from '@porsche-design-system/components-react'
import { GuardedRoute } from 'components/guarded-route/GuardedRoute'
import { ACTIVATION_ERROR, INACTIVE, NOT_REGISTERED, REGISTERED } from 'utils/path'
import NeedHelp from 'components/need-help/NeedHelp'
import { TopBar } from 'components/top-bar/TopBar'
import { RegistrationState } from 'components/registration-state/RegistrationState'
import EuCaPopover from 'components/eucapopover/EuCaPopover'
import 'components/ca-rel4/mno-content/CanadaRel4Page.scss'
import { Col } from 'react-bootstrap'
import { ConnectivityProductUIState, ErrorStateData } from 'model/uistate/ConnectivityProductUIState'
import { FutureResult } from 'model/FutureResult'
import { usePath } from 'hooks/hooks'
import { RogersRefs } from 'api/CaApi'

const CanadaRel4Page = ({
  connectivityProductState,
}: {
  connectivityProductState: FutureResult<ConnectivityProductUIState>
}): JSX.Element => {
  const Registered = useMemo(() => lazy(() => import('components/ca-rel4/ca-registered/CaRegistered')), [])
  const NotRegistered = useMemo(() => lazy(() => import('components/ca-rel4/ca-not-registered/CaNotRegistered')), [])
  const DataPlanInactive = useMemo(
    () => lazy(() => import('components/ca-rel4/ca-inactive-dataplan/CaInactiveDataplan')),
    []
  )
  const ActivationError = useMemo(() => lazy(() => import('components/activation-error/ActivationError')), [])

  const path = usePath(connectivityProductState)
  const refs = connectivityProductState?.data?.refs as RogersRefs

  return (
    <>
      <Col xs={{ span: 10, offset: 1 }} data-testid='mno-content-ca-rel4'>
        <div className='top-bar-wrapper'>
          <TopBar
            refs={refs}
            registrationState={<RegistrationState registrationState={path} />}
            popover={<EuCaPopover refs={refs} translationPrefix={'app.ca.popover'} />}
          />
        </div>
        <div>
          <Suspense fallback={<PSpinner size='large' aria={{ 'aria-label': 'Redirecting to page' }} />}>
            <Routes>
              <Route
                path={`/${REGISTERED}`}
                element={
                  <GuardedRoute loading={!connectivityProductState} authorized={path === REGISTERED} redirectTo=''>
                    <Registered productState={connectivityProductState} />
                  </GuardedRoute>
                }
              />

              <Route
                path={`/${NOT_REGISTERED}`}
                element={
                  <GuardedRoute loading={!connectivityProductState} authorized={path === NOT_REGISTERED} redirectTo=''>
                    <NotRegistered />
                  </GuardedRoute>
                }
              />

              <Route
                path={`/${INACTIVE}`}
                element={
                  <GuardedRoute loading={!connectivityProductState} authorized={path === INACTIVE} redirectTo=''>
                    <DataPlanInactive refs={refs} />
                  </GuardedRoute>
                }
              />

              <Route
                path={`/${ACTIVATION_ERROR}`}
                element={
                  <GuardedRoute
                    loading={!connectivityProductState}
                    authorized={path === ACTIVATION_ERROR}
                    redirectTo=''
                  >
                    <ActivationError activationError={(connectivityProductState as ErrorStateData).productError} />
                  </GuardedRoute>
                }
              />
            </Routes>
          </Suspense>
        </div>

        {connectivityProductState.pending && (
          <PSpinner size='large' aria={{ 'aria-label': 'Loading mno refs' }} data-testid='spinner' />
        )}
      </Col>
      {refs && <NeedHelp mno={connectivityProductState.data?.mnoInformation?.mno} {...refs} />}
    </>
  )
}

export default CanadaRel4Page
